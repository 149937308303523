import React from "react";
import web3 from "./web3";
import dfst from "./dfst_mainnet";
import tr from "./token_receiver";

class App extends React.Component {
  state = {
    accounts: null,
    manager: "",
    players: [],
    balance: "",
    value: "",
    numberToMint: 1,
    numberToExchange: 1,
    message: "",
    tokensOwned: 0,
    nftsOwned: 0,
    totalSupply: 0
  };


  async componentDidMount() {
    await this.updatePageStatus();
  }

  updatePageStatus = async () => {
      const tokensOwned = await this.getTokens();
      const nftsOwned = await this.getNFTs();
      const supply = await this.getSupply();
      this.setState({totalSupply: supply});
      this.setState({tokensOwned: tokensOwned});
      this.setState({nftsOwned: nftsOwned});
  }

    onConnect = async (event) => {
        this.state.accounts = await web3.eth.getAccounts();
    }



  onPublicMint = async (event) => {
    event.preventDefault();
        if(!this.state.accounts){
            this.state.accounts = await web3.eth.getAccounts();
        }

    this.setState({ message: "Waiting on success of Mint..." });

    const pay = this.state.numberToMint * .05;
    console.log(pay);
    const mint = await tr.methods.mint(this.state.numberToMint).send({
        from: this.state.accounts[0],
        value: web3.utils.toWei(pay.toString(), "ether"),
    });
    if(mint){
        this.setState({ message: "Complete!" });
        await this.updatePageStatus();
    }else{
        this.setState({ message: "Something went wrong..." });
    }
  };

    onTokenMint = async (event) => {
        event.preventDefault();

        if(!this.state.accounts){
            this.state.accounts = await web3.eth.getAccounts();
        }

        this.setState({ message: "Waiting on success of Approval..." });
        const oneERC20 = 1;
        const payERC20 = oneERC20 * this.state.numberToExchange;
        console.log(tr._address);
        const approval = await dfst.methods.approve(tr._address, payERC20).send({
            from: this.state.accounts[0]
        });
        if(approval){
            this.setState({ message: "Approved: you must now confirm the Deposit" });
        }else{
            this.setState({ message: "Something went wrong with ERC20 approval..." });
        }

        const deposit = await tr.methods.deposit(payERC20).send({
            from: this.state.accounts[0]
        });
        if(deposit){
            this.setState({ message: "Complete!" });
            await this.updatePageStatus();
        }else{
            this.setState({ message: "Something went wrong with ERC20 transfer..." });
        }
    };


  getTokens = async () => {
      try{
          if(!this.state.accounts){
              this.state.accounts = await web3.eth.getAccounts();
          }
          console.log(this.state.accounts[0]);
          const tokenBalance = await dfst.methods.balanceOf(this.state.accounts[0]).call();
          console.log("DFSTs owned: " + tokenBalance);
          this.setState({tokensOwned: tokenBalance});
          return tokenBalance;
      } catch (e) {
          console.log("contract does not exist on this network");
          return 0;
      }
  }

    getNFTs = async () => {
        try{
            if(!this.state.accounts){
                this.state.accounts = await web3.eth.getAccounts();
            }
            const nftsOwned = await tr.methods.balanceOf(this.state.accounts[0]).call();
            console.log("NFTs owned: " + nftsOwned);
            this.setState({nftsOwned: nftsOwned});
            return nftsOwned;
        } catch (e) {
            console.log("contract does not exist on this network");
            return 0;
        }
    }

    getSupply = async () => {
        try{
            const totalSupply = await tr.methods.totalSupply().call();
            console.log("totalSupply: " + totalSupply);
            this.setState({totalSupply: totalSupply});
            return totalSupply;
        } catch (e) {
            console.log("contract does not exist on this network");
            return 0;
        }
    }

  render() {
    return (
        <div>
            <header className="header">
                <div className="container">
                    <div className="header__row">

                        <div className="header__logo">
                            <a href="https://opensea.io/collection/degenfantasysports" rel="noreferrer"  target="_blank"><img
                                src="./logo.png" alt="DFS Logo"/></a>
                        </div>

                        <div className="header__right">

                            <a href="https://twitter.com/DegenSportsNFT" rel="noreferrer"  target="_blank" className="head-twitter"></a>
                            <a href="https://discord.gg/nfsl" rel="noreferrer"  target="_blank" className="head-discord"></a>
                            <a href="https://etherscan.io/address/0x4a59FB5B4ffe615e57C4dC0C3ff18F0808D65843"
                               target="_blank" rel="noreferrer"  className="head-etherscan"></a>

                            {!this.state.accounts &&
                                <button onClick={this.onConnect} className="btn-head" id="connect_button">Connect wallet</button>
                            }
                            {this.state.accounts && this.state.accounts[0] &&
                                <button className="btn-head" id="connect_button">...{this.state.accounts[0].substring(this.state.accounts[0].length - 14)}</button>
                            }
                        </div>
                    </div>
                </div>
            </header>
            <div className="main">
                <div className="container">
                    <div className="main__row">
                        {this.state.tokensOwned < 1 &&
                            <div className="main__left">
                                <div className="number" id="tokens_available">{this.state.totalSupply}/10000</div>
                                <div className="form-text"> 0.05 ETH each</div>
                                <div className="form-text"></div>
                                <div className="form-row">
                                    <input value={this.state.numberToMint}
                                           type="number"
                                           placeholder="Max 20 per transaction"
                                           className="in-form js-in-number"
                                           onChange={(event) => this.setState({ numberToMint: event.target.value })}
                                           id="tokens_amount"/>
                                    <button className="btn-form"
                                            onClick={this.onPublicMint}
                                            id="mint_button">Mint {this.state.numberToMint}</button>
                                </div>

                                <div className="form-text">
                                    <div className="dfs-yellow">{this.state.message}</div>
                                    <br/>
                                    <div className="dfst-status">
                                        You own {" "} {this.state.tokensOwned} DFST ERC20 Tokens.
                                    </div>
                                    <br/>
                                    <div className="dfst-status">
                                        You own {" "} {this.state.nftsOwned} DFS ERC721 Teams.
                                    </div>
                                    <br/>
                                    Degens. Sports. NFTs. <br/><br/>
                                    Starting with good ol' American Football.
                                    <br/>
                                    Mint Open thru Week 12, Nov 28th
                                    <br/>
                                    ???Who's Your Team???

                                </div>
                            </div>
                        }
                        {this.state.tokensOwned > 0 &&
                            <div className="main__left">
                                <div className="number" id="tokens_available">{this.state.totalSupply}/10000</div>
                                <div className="form-text"> 1 DFST Token each</div>
                                <div className="form-text"></div>
                                <div className="form-row">
                                    <input value={this.state.numberToExchange}
                                           type="number"
                                           placeholder="Max 20 per transaction"
                                           className="in-form js-in-number"
                                           onChange={(event) => this.setState({ numberToExchange: event.target.value })}
                                           id="tokens_amount"/>
                                    <button className="btn-form"
                                            onClick={this.onTokenMint}
                                            id="mint_button">Approve/Deposit {this.state.numberToExchange}</button>
                                </div>

                                <div className="form-text">
                                    <div className="dfs-yellow">{this.state.message}</div> <br/>
                                    <div className="dfst-status">
                                        You own {" "} {this.state.tokensOwned} DFST ERC20 Tokens.
                                    </div>
                                    <br/>
                                    <div className="dfst-status">
                                        You own {" "} {this.state.nftsOwned} DFS ERC721 Teams.
                                    </div>
                                    <br/>
                                    Degens. Sports. NFTs. <br/><br/>
                                    Starting with good ol' American Football.
                                    <br/>
                                    Mint Open thru Week 12, Nov 28th
                                    <br/>
                                    ???Who's Your Team???
                                    <br/>
                                    <sub><i>*free mint tokens require multiple actions: Approve/Deposit</i></sub>

                                </div>
                            </div>
                        }
                        <div className="main__right2">
                            <img src="/gif.gif"/>
                            {/*<img src="/logo.png"/>*/}
                        </div>

                    </div>
                </div>
            </div>
            <footer className="footer">
                <div className="container">
                    <div className="footer__row">
                        <div className="text-center copyright">
                            <span>© 2022 DEGEN FANTASY SPORTS</span>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    );
  }
}
export default App;
